import { Stack } from '@mui/material';
import { Loader } from '@treedis/tour-loader';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useLoadingProgress } from '@/hooks/useLoadingProgress';

export const LoadingProgress = () => {
  const { loadingProgress } = useLoadingProgress();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (loadingProgress === 100 && showLoader) {
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    }
  }, [loadingProgress, showLoader]);

  return showLoader && isNumber(loadingProgress) ? (
    <Stack
      id="loading-spinner"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loader value={loadingProgress} position="relative" />
    </Stack>
  ) : (
    <></>
  );
};

export default LoadingProgress;
