/* eslint-disable react/no-danger */
import Head from 'next/head';
import Script from 'next/script';

type PageHeader = {
  slug: string;
  title: string;
  description: string;
  tourImage: string;
  website: string;
  fbPixel: string;
  children: any;
  htmlCode: string;
  faviconUrl: string;
  type: string;
  fbAppId: string;
  charset: string;
};

const PageHeader = ({ slug, title, description = '', tourImage, website, fbPixel, children, htmlCode, faviconUrl, type, fbAppId, charset }: PageHeader) => (
  <>
    <Head>
      <title>{title}</title>
      <meta property="og:description" content={description} />
      <meta property="fb:app_id" content={fbAppId && fbAppId.length > 0 ? fbAppId : '741048509750318'} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={tourImage} />
      <meta property="og:image:secure_url" content={tourImage} />
      <meta property="og:image:width" content="640" />
      <meta property="og:image:height" content="442" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      <meta property="og:url" content={`https://${website}/${type}/${slug}`} />
      <meta charSet={charset} />

      {faviconUrl && <link rel="icon" sizes="16x16" href={`${faviconUrl}`} />}
      <noscript>
        <img height="1" width="1" alt="" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=904257603695477&ev=PageView&noscript=1" />
      </noscript>

      <Script
        id="fb-connect"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '904257603695477');
              ${fbPixel && fbPixel.length > 0 ? `fbq('init', ${fbPixel});` : ''}
              fbq('track', 'PageView');
        `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `window.fbAsyncInit = function() {
          FB.init({
            appId            : '${fbAppId && fbAppId.length > 0 ? fbAppId : '741048509750318'}',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v15.0'
          });
        };`,
        }}
      />
    </Head>

    {htmlCode && (
      <div
        className="htmlCode"
        dangerouslySetInnerHTML={{
          __html: htmlCode,
        }}
      />
    )}

    {children}
  </>
);

export default PageHeader;
